import {createContext, useContext, useEffect, useState } from "react"
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { cnpj } from "cpf-cnpj-validator"
import { useQuery } from "react-query"

import { getCNPJInfo, getCepInfo, createNewOscForm, preSaveNewOscForm } from "./_requests"

const newOscFormSchema = () => Yup.object().shape({
    cnpj: Yup.string()
      .test(
        "cnpjValidation",
        "CNPJ invalido!",
        item => cnpj.isValid(item)
      )
      .required("CNPJ 'e obrigatorio!"),
    cep: Yup.string()
      .transform((value) => value.replaceAll("_",""))
      .min(9, "CEP invalido!")
      .max(9, "CEP invalido!")
      .test(
        "cepValidation",
        "CEP invalido!",
        (_, obj) => obj.options.context.cepIsValid
      )
      .required("CEP 'e obrigatorio!"),
    razao_social: Yup.string().nullable(),
    street: Yup.string().nullable(),
    street_number: Yup
      .number()
      .transform((value) => Number.isNaN(value) ? null : value )
      .required("É necessario preencher o número!"),
    neighborhood: Yup.string().nullable(),
    state: Yup.string().nullable(),
    city: Yup.string().nullable(),
    phone: Yup.string().required("Telefone é obrigatorio!"),
    organization_site: Yup.string().nullable(),
    social_midia: Yup.string().nullable(),
    priority_category: Yup.string().required("Categoria Prioritária obrigatioria!").test(
      "placeholderChekc",
      "Categoria Prioritária obrigatioria!",
      item => item !== ""
    ),
    disponibility_collection: Yup.string().required("Disponibilidade para coleta obrigatioria!").test(
      "placeholderChekc",
      "Disponibilidade para coleta obrigatioria!",
      item => item !== ""
    ),
    activities_detail: Yup.string().min(200, "Necessario ter no minimo 200 caracteres.").max(1000, "Necessario ter no maximo 1000 caracteres.").required("Detalhes das atividades Obrigatorios!"),
    collection_restriction_detail: Yup.string().max(1000, "Necessario ter no maximo 1000 caracteres."),
    has_registration_form: Yup.boolean().default(false),
    people_benefited: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
      .required("Necessario informar um numero.")
      .transform((value) => Number.isNaN(value) ? null : value )
      .typeError('Resposta precisa ser um numero.'),
    donation_destination: Yup.string().max(1000, "Necessario ter no maximo 1000 caracteres."),
    responsable: Yup.object({
      name: Yup.string().required("Nome Responsável Obrigatorio!"),
      role: Yup.string().required("Cargo Responsável Obrigatorio!"),
      email: Yup.string().matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        , "Email invalido").required("Email Responsável Obrigatorio!"),
      phone: Yup.string().required("Telefone Responsável Obrigatorio!"),
    }),
    target_audience_create: Yup.array(Yup.string()).test(
      "shapeTest",
      "Público-Alvo Obrigatorio!",
      item => !!item?.length
    ).transform((value) =>{
      return Array.isArray(value) ? value : []
    } ),
    recess_months_create: Yup.array(
      Yup
      .number()
      .transform((value) => Number.isNaN(value) ? null : value )
    ).transform((value) =>{
      return Array.isArray(value) ? value : []
    } ),
    quantity_workers: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
    .transform((value) => Number.isNaN(value) ? null : value)
    .required("Número de funcionarios Obrigatorio!"),
    quantity_volunteers: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
    .transform((value) => Number.isNaN(value) ? null : value)
    .required("Número de voluntarios Obrigatorio!"),
    quantity_people_served_monthly: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
    .transform((value) => Number.isNaN(value) ? null : value)
    .required("Número de pessoas atendias Obrigatorio!"),
    age_group__id: Yup.array(
      Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
          .transform((value) => Number.isNaN(value) || typeof value === "boolean" ? null : value)
    ).test(
      "shapeTest",
      "Faixa Etaria Obrigatoria!",
      item => {
        return !!item?.length
      }
    ).transform((value) =>{
      return Array.isArray(value) ? value : []
    }),
    age_group__quantity_people_served_monthly1: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
    .transform((value) => Number.isNaN(value) || typeof value === "boolean" ? null : value )
    .nullable()
    .test(
      "age_group_test",
      "Necessario informar quantidade atendida!",
      (item, obj) => {
        return obj.parent.age_group__id && (obj.parent.age_group__id?.every((age_group) => age_group !== 1) || (!!item && obj.parent.age_group__id?.some((age_group) => age_group === 1)))
      }
    ),
    age_group__quantity_people_served_monthly2: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
    .transform((value) => Number.isNaN(value) || typeof value === "boolean" ? null : value )
    .nullable()
    .test(
      "age_group_test",
      "Necessario informar quantidade atendida!",
      (item, obj) => {
        return obj.parent.age_group__id && (obj.parent.age_group__id?.every((age_group) => age_group !== 2) || (!!item && obj.parent.age_group__id?.some((age_group) => age_group === 2)))
      }
    ),
    age_group__quantity_people_served_monthly3: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
    .transform((value) => Number.isNaN(value) || typeof value === "boolean" ? null : value )
    .nullable()
    .test(
      "age_group_test",
      "Necessario informar quantidade atendida!",
      (item, obj) => {
        return obj.parent.age_group__id && (obj.parent.age_group__id?.every((age_group) => age_group !== 3) || (!!item && obj.parent.age_group__id?.some((age_group) => age_group === 3)))
      }
    ),
    age_group__quantity_people_served_monthly4: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
    .transform((value) => Number.isNaN(value) || typeof value === "boolean" ? null : value )
    .nullable()
    .test(
      "age_group_test",
      "Necessario informar quantidade atendida!",
      (item, obj) => {
        return obj.parent.age_group__id && (obj.parent.age_group__id?.every((age_group) => age_group !== 4) || (!!item && obj.parent.age_group__id?.some((age_group) => age_group === 4)))
      }
    ),
    age_group__quantity_people_served_monthly5: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
    .transform((value) => Number.isNaN(value) || typeof value === "boolean" ? null : value )
    .nullable()
    .test(
      "age_group_test",
      "Necessario informar quantidade atendida!",
      (item, obj) => {
        return obj.parent.age_group__id && (obj.parent.age_group__id?.every((age_group) => age_group !== 5) || (!!item && obj.parent.age_group__id?.some((age_group) => age_group === 5)))
      }
    ),
    has_adequate_space: Yup.boolean().default(false),
    has_freezing_system: Yup.boolean().default(false),
    has_local_kitchen: Yup.boolean().default(false),
    has_refectory: Yup.boolean().default(false),
    serve_local_meals: Yup.boolean().default(false),
    meal_type: Yup.array(
      Yup.object({
        week_serves: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.').nullable().transform((value) => Number.isNaN(value) ? null : value ),
        people_server_daily: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
        .nullable()
        .transform((value) => Number.isNaN(value) ? null : value )
        .test(
          "people_server_daily",
          "Necessario Informar pessoas serivdas",
          (value,obj) => {
            return !obj.parent.week_serves || parseInt(obj.parent.week_serves) === 0 || (parseInt(obj.parent.week_serves) > 0 && !!value)
          }
        )
      })
    ).transform((value) =>{
      return Array.isArray(value) ? value : []
    }),
    has_nutritionist: Yup.boolean().default(false),
    food_distribution: Yup.object({
      has_food_distribution: Yup.boolean().transform((value) => JSON.parse(value)).default(false),
      distribution_type_create: Yup.array(
        Yup.string()
      ).test(
        "conditional_test",
        "Tipo de Distribuicão Obrigatorio",
        (value, obj) => !obj.parent.has_food_distribution || (obj.parent.has_food_distribution && value?.length)
      ).transform((value) =>{
        return Array.isArray(value) ? value : []
      }),
      monthly_distribution1: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
      .nullable()
      .transform((value) => Number.isNaN(value) ? null : value )
      .test(
        "conditional_test",
        "Preencha somente com números",
        (value, obj) => !obj.parent.has_food_distribution || (obj.parent.distribution_type_create?.every((age_group) => age_group !== "1") || (!!value && obj.parent.distribution_type_create?.some((age_group) => age_group === "1")))
      ),
      monthly_distribution2: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
      .nullable()
      .transform((value) => Number.isNaN(value) ? null : value )
      .test(
        "conditional_test",
        "Preencha somente com números",
        (value, obj) => !obj.parent.has_food_distribution || (obj.parent.distribution_type_create?.every((age_group) => age_group !== "2") || (!!value && obj.parent.distribution_type_create?.some((age_group) => age_group === "2")))
      ),
      monthly_distribution3: Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
      .nullable()
      .transform((value) => Number.isNaN(value) ? null : value )
      .test(
        "conditional_test",
        "Preencha somente com números",
        (value, obj) => !obj.parent.has_food_distribution || (obj.parent.distribution_type_create?.every((age_group) => age_group !== "3") || (!!value && obj.parent.distribution_type_create?.some((age_group) => age_group === "3")))
        
      ),
      monthly_distribution_custom: Yup.array(
        Yup.number().integer('Numero em formato invalido, por favor utilize apenas digitos.').min(0,'Numero em formato invalido, por favor utilize apenas digitos.')
        .nullable()
        .transform((value) => Number.isNaN(value) ? null : value )
        .test(
          "conditional_test",
          "Preencha somente com números",
          (value, obj) => {
            const isValueUndefined = value === undefined
            const isValueNull = value === null
            if(isValueUndefined){
              return true
            }

            return !isValueNull
          }
          
        )).transform((value) =>{
          return Array.isArray(value) ? value : []
        }),
    }),
    educational_usage: Yup.object({
      has_educational_usage: Yup.boolean().transform((value) => JSON.parse(value)).default(false),
      educational_usage_type_create: Yup.array(
        Yup.string()
      ).test(
        "conditional_test",
        "Tipo de Atividade Obrigatorio",
        (value, obj) => !obj.parent.has_educational_usage || (obj.parent.has_educational_usage && value?.length) 
      ).transform((value) =>{
        return Array.isArray(value) ? value : []
      }),
      usage_details: Yup.string().max(1000, "Necessario ter no maximo 1000 caracteres.")
      .test(
        "conditional_test",
        "Descricão das atividades Obrigatoria",
        (value, obj) => {
          return !obj.parent.has_educational_usage || (obj.parent.has_educational_usage && !!value)
        }
      ).test(
        "conditional_min_test",
        "Necessario ter no minimo 100 caracteres.",
        (value, obj) => {
          return !obj.parent.has_educational_usage || (obj.parent.has_educational_usage && !!value && value.length >= 100)
        }
      ),
    }),
    other_parters: Yup.object({
      has_other_parters: Yup.boolean().transform((value) => JSON.parse(value)).default(false),
      food_type_create: Yup.array(
        Yup.string()
      ).test(
        "conditional_test",
        "Tipo de Alimento Obrigatorio",
        (value, obj) => !obj.parent.has_other_parters || (obj.parent.has_other_parters && value?.length)
      ).transform((value) =>{
        return Array.isArray(value) ? value : []
      }),
      partner_type_create: Yup.array(
        Yup.string()
      ).test(
        "conditional_test",
        "Tipo de Parceiro Obrigatorio",
        (value, obj) => !obj.parent.has_other_parters || (obj.parent.has_other_parters && value?.length)
      ).transform((value) =>{
        return Array.isArray(value) ? value : []
      }),
      frequency_receved_create: Yup.string().test(
        "conditional_test",
        "Fequencia de recebimento Obrigatorio",
        (value, obj) => !obj.parent.has_other_parters || (obj.parent.has_other_parters && value?.length)
      )
    }),
    donation_extraction: Yup.object({
      has_donation_extraction: Yup.boolean().transform((value) => JSON.parse(value)).default(false),
      type_of_transport_create: Yup.string().test(
        "conditional_test",
        "Descricão do Tipo de transporte Obrigatorio",
        (value, obj) => !obj.parent.has_donation_extraction || (obj.parent.has_donation_extraction && value?.length)
      ),
      vehicle_description: Yup.string()
      .test(
        "conditional_test",
        "Descricão do tipo de veiculo Obrigatorio",
        (value, obj) => !obj.parent.has_donation_extraction || (obj.parent.has_donation_extraction && !!value)
      )
    }),
    has_public_agreement: Yup.boolean().default(false),
    has_specific_agreement: Yup.boolean().default(false),
    has_updated_cnpj: Yup.boolean().default(false),
    has_updated_member_registration: Yup.boolean().default(false),
    has_updated_minute_inauguration_assembly: Yup.boolean().default(false),
    has_updated_health_permit: Yup.boolean().default(false),
})

const NewOscContext = createContext(null)

const NewOscProvider = ({ children }) => {

  const [cepIsValid, setCepIsValid] = useState(true)
  const [streetDisabled, setStreetDisabled] = useState(true)
  const [neighborhoodDisabled, setNeighborhoodDisabled] = useState(true)
  const [swalProps, setSwalProps] = useState({});
  
  const {
      register,
      getValues,
      handleSubmit,
      watch,
      setValue,
      trigger,
      reset,
      control,
      formState: {errors},
  } = useForm( { resolver: yupResolver(newOscFormSchema()), context: {cepIsValid} } )

  const [newOscFormData, setNewOscFormData] = useState(null)
  const [preSaveNewOscFormData, setPreSaveNewOscFormData] = useState(null)
  const [acceptedTerms, setAcceptedTerms] = useState(false)


  const formatAgeGroup = (ageGroupIds, ageGroupPeoples) => ageGroupIds.map((value) => ({
      age_group: value,
      quantity_people_served_monthly: ageGroupPeoples[`age_group__quantity_people_served_monthly${value}`]
    })
  )
  
  const formatMonthlyDistribution = (distributionIds, monthlyDistributions) => distributionIds.map((value) => ({
      distribution_type_create: value,
      monthly_distribution: monthlyDistributions[`${value}`]
    })
  )

  const formatMealType = (mealTypes) => {
    const formattedMealType = mealTypes?.map((mealType, id) => {
      if(mealType.week_serves >= 0){
        return {
          meal_type: id,
          ...mealType
      
        }
      }
      return null
    })
    const filteredMealType = formattedMealType?.filter((element) => element != null)
    return filteredMealType
  }

  const sendTest = (refetch) => {
    let data = getValues()

    if (!JSON.parse(data.food_distribution["has_food_distribution"])){
      delete data["food_distribution"]
    }
    if (!JSON.parse(data.educational_usage["has_educational_usage"])){
      delete data["educational_usage"]
    }
    if (!JSON.parse(data.other_parters["has_other_parters"])){
      delete data["other_parters"]
    }
    if (!JSON.parse(data.donation_extraction["has_donation_extraction"])){
      delete data["donation_extraction"]
    }
    if (!JSON.parse(data["serve_local_meals"])){
      delete data["meal_type"]
    }

    const ageGroupIds = data.age_group__id
    const ageGroupPeoples = {
      age_group__quantity_people_served_monthly1: data.age_group__quantity_people_served_monthly1,
      age_group__quantity_people_served_monthly2: data.age_group__quantity_people_served_monthly2,
      age_group__quantity_people_served_monthly3: data.age_group__quantity_people_served_monthly3,
      age_group__quantity_people_served_monthly4: data.age_group__quantity_people_served_monthly4,
      age_group__quantity_people_served_monthly5: data.age_group__quantity_people_served_monthly5,
    }
    const distributionIds = (data?.food_distribution?.distribution_type_create) ? data?.food_distribution?.distribution_type_create : []
    const monthlyDistributions = {}
    distributionIds.forEach((id, index) => {
      let clearData  = data.food_distribution[`monthly_distribution${id}`]
      if (clearData === undefined){
        clearData = data.food_distribution.monthly_distribution_custom[index]
      }
      monthlyDistributions[id] = clearData
    })
    data.age_group_mm = formatAgeGroup(ageGroupIds, ageGroupPeoples)
    data.meal_types_mm = formatMealType(data?.meal_type)
    data.food_distribution_mm = formatMonthlyDistribution(distributionIds, monthlyDistributions)
    data.recess_months_create = data?.recess_months_create?.length ? data?.recess_months_create?.map((value) => parseInt(value)) : []
    data.pre_save = preSaveId
    data.razao_social = "teste"

    setNewOscFormData(data)
    
    setPreSaveId(null)
    setStreetDisabled(true)
    setNeighborhoodDisabled(true)

    setSwalProps({
      show: true,
      title: 'Sua resposta foi enviada com sucesso!',
      text: 'Quer saber mais sobre nosso trabalho e acompanhar as ações de perto? https://www.connectingfood.com',
      icon: 'success',
    });
  }

  const [preSaveId, setPreSaveId] = useState(null)

  const preSave = async () => {
    const data = getValues()
    data.id = preSaveId || null
    setPreSaveNewOscFormData(data)
    return data
  }

  const useCreateNewOscForm = (data) => useQuery(['new-osc-form', {data: newOscFormData}], createNewOscForm, {refetchOnWindowFocus: false, enabled: !!newOscFormData, retry: 0})
  
  const usePreSaveNewOscForm = (data) => useQuery(['pre-save-new-osc-form', {data: preSaveNewOscFormData}], preSaveNewOscForm, {refetchOnWindowFocus: false, enabled: !!preSaveNewOscFormData, retry: 0})

  const handleCNPJInfo = async (cnpj) => {
    try {
      const result = await getCNPJInfo(cnpj)
      setValue("razao_social", result["razao_social"])
      trigger('cnpj')
      
    } catch (error) {
      trigger('cnpj')
    }
  }
  
  const handleClearCNPJInfo = () => {
    setValue("razao_social", null)
  }
  
  const handleCepInfo = async (cep) => {
    const result = await getCepInfo(cep)
    if(Object.keys(result).includes("erro")){
      setCepIsValid(false)
      return null
    }
    setCepIsValid(true)

    setStreetDisabled(result["logradouro"] !== "")
    setNeighborhoodDisabled(result["bairro"] !== "")
    
    setValue("street", result["logradouro"])
    setValue("neighborhood", result["bairro"])
    setValue("city", result["localidade"])
    setValue("state", result["uf"])
  }

  const handleClearCepInfo = () =>{
    setValue("street", null)
    setValue("neighborhood", null)
    setValue("city", null)
    setValue("state", null)
  }

  const formCnpj = watch("cnpj")
  const formCep = watch("cep")
  const formPhone = watch("phone")
  const formAgeGroupId = watch("age_group__id")

  const showAgeGroupInput = (idToSearch) => {
    return (formAgeGroupId)? formAgeGroupId?.some((ageGroup) => parseInt(ageGroup) === idToSearch) : null
  }
  
  useEffect(
    () => {
      if(cnpj.isValid(formCnpj)){
        handleCNPJInfo(cnpj.strip(formCnpj))
      }else{
        if(cnpj.strip(formCnpj)?.length === 14){
          trigger('cnpj')
        }
        handleClearCNPJInfo()
      }

      // eslint-disable-next-line
    }, [formCnpj]
  )
  useEffect(
    () => {
      if(formCep?.replaceAll("_", "").replaceAll("-", "")?.length === 8){
        const clearCep = formCep.replace("-","")
        handleCepInfo(clearCep)
      }else{
        handleClearCepInfo()
      } 
      // eslint-disable-next-line
    }, [formCep]
    )
    
    useEffect(() => {
      if(formCep?.replaceAll("_", "").replaceAll("-", "")?.length === 8){
        trigger("cep")
      }
      // eslint-disable-next-line
    }, [cepIsValid])

  const phoneMask = (phone) => {
    const clearPhone = phone?.replaceAll("_", "").replaceAll("-", "").replaceAll(" ", "")
    if(clearPhone && clearPhone?.length > 4 && clearPhone[4] !== "9"){
      return "(99) 9999-9999"
    }
    return "(99) 99999-9999"
  }

  const formatDataToSelect = (data) => {
    return data.map((value) => {
        return { label: value.name, value: value.id}
    })
}

  const handlePhoneMaskChange = () => {
    return phoneMask(formPhone)
  }
  
  return (
    <NewOscContext.Provider
      value={{
        register,
        getValues,
        setValue,
        errors,
        control,
        watch,
        handleSubmit,
        sendTest,
        reset,
        trigger,
        swalProps,
        acceptedTerms,
        setNewOscFormData,
        setAcceptedTerms,
        setSwalProps,
        handlePhoneMaskChange,
        showAgeGroupInput,
        formatDataToSelect,
        useCreateNewOscForm,
        preSave,
        preSaveId,
        setPreSaveId,
        usePreSaveNewOscForm,
        streetDisabled,
        setStreetDisabled,
        neighborhoodDisabled,
        setNeighborhoodDisabled,
      }}
    >
      {children}
    </NewOscContext.Provider>
  )
}

const useNewOsc = () => useContext(NewOscContext)

export { NewOscProvider, useNewOsc }
